@font-face {
  font-family: "icons";
  src: url("/sites/all/themes/calinerra/fonts/icons.eot");
  src: url("/sites/all/themes/calinerra/fonts/icons.eot?#iefix") format("embedded-opentype"), url("/sites/all/themes/calinerra/fonts/icons.woff") format("woff"), url("/sites/all/themes/calinerra/fonts/icons.ttf") format("truetype"), url("/sites/all/themes/calinerra/fonts/icons.svg#icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Tstar Bold";
  src: url("/sites/all/themes/calinerra/fonts/tstar-bold-webfont.eot");
  src: url("/sites/all/themes/calinerra/fonts/tstar-bold-webfont.eot?#iefix") format("embedded-opentype"), url("/sites/all/themes/calinerra/fonts/tstar-bold-webfont.woff") format("woff"), url("/sites/all/themes/calinerra/fonts/tstar-bold-webfont.ttf") format("truetype"), url("/sites/all/themes/calinerra/fonts/tstar-bold-webfont.svg#tstar-bold-webfont") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Tstar Headline";
  src: url("/sites/all/themes/calinerra/fonts/tstar-headline-webfont.eot");
  src: url("/sites/all/themes/calinerra/fonts/tstar-headline-webfont.eot?#iefix") format("embedded-opentype"), url("/sites/all/themes/calinerra/fonts/tstar-headline-webfont.woff") format("woff"), url("/sites/all/themes/calinerra/fonts/tstar-headline-webfont.ttf") format("truetype"), url("/sites/all/themes/calinerra/fonts/tstar-headline-webfont.svg#tstar-headline-webfont") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Tstar Regular";
  src: url("/sites/all/themes/calinerra/fonts/tstar-regular-webfont.eot");
  src: url("/sites/all/themes/calinerra/fonts/tstar-regular-webfont.eot?#iefix") format("embedded-opentype"), url("/sites/all/themes/calinerra/fonts/tstar-regular-webfont.woff") format("woff"), url("/sites/all/themes/calinerra/fonts/tstar-regular-webfont.ttf") format("truetype"), url("/sites/all/themes/calinerra/fonts/tstar-regular-webfont.svg#tstar-regular-webfont") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Tstar Italic";
  src: url("/sites/all/themes/calinerra/fonts/tstar-italic-webfont.eot");
  src: url("/sites/all/themes/calinerra/fonts/tstar-italic-webfont.eot?#iefix") format("embedded-opentype"), url("/sites/all/themes/calinerra/fonts/tstar-italic-webfont.woff") format("woff"), url("/sites/all/themes/calinerra/fonts/tstar-italic-webfont.ttf") format("truetype"), url("/sites/all/themes/calinerra/fonts/tstar-italic-webfont.svg#tstar-italic-webfont") format("svg");
  font-weight: 300;
  font-style: normal;
}
