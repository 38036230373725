//
// FONTS
// ---------------------------------------------------------------------
// This file contains only font-face imports.
// Generates a seperate CSS file to be picked up by Balance and rendered
// in the <head> tag
//

@import 'config/settings';

@mixin font-face($font-family, $font-weight, $font-style, $src-filename) {
  @font-face {
    font-family: $font-family;
    src: url('#{$font-path}#{$src-filename}.eot');
    src: url('#{$font-path}#{$src-filename}.eot?#iefix') format('embedded-opentype'),
         url('#{$font-path}#{$src-filename}.woff') format('woff'),
         url('#{$font-path}#{$src-filename}.ttf') format('truetype'),
         url('#{$font-path}#{$src-filename}.svg##{$src-filename}') format('svg');
    font-weight: $font-weight;
    font-style: $font-style;
  }
}

//
//  ICONS
//

@include font-face($icon-font, normal, normal, $icon-font);

//
// FONTS
//
@include font-face('Tstar Bold', 300, normal, 'tstar-bold-webfont');
@include font-face('Tstar Headline', 300, normal, 'tstar-headline-webfont');
@include font-face('Tstar Regular', 300, normal, 'tstar-regular-webfont');
@include font-face('Tstar Italic', 300, normal, 'tstar-italic-webfont');
