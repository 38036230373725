//
// SETTINGS
// ---------------------------------------------------------------------
// This file contains the theme' settings.
//

// COLORS

$color-background:    #ffffff;

$color-primary:       #f16000;
$color-secondary:     #38deb0;
$color-black:         #000000;

$color-grey:          #aaaaaa;
$color-grey-lighter:   #dee1e3;
$color-grey-light:    #f5f5f5;

$color-dark:          #3d3f41;
$color-white:         #ffffff;
$color-muted:         #999999; // for muted text, like descriptions and captions
$color-heading:       #3a6f97;
$color-link:          $color-primary;

$color-border:        #eff1f2;

$color-status:        #9dd474;
$color-warning:       #ffcb67;
$color-error:         #f4171e;

$color-shadow:        rgba(0,0,0, 0.5);

$color-federal-light: 		  #cccccc;
$color-federal-medium: 	    #333333;
$color-federal-dark: 		    #666666;

$default-text-color:  #333333;
$form-hover-color: 		#babec4;
$form-focus-color: 		$color-primary;
$form-shadow-color:   #232529;

// TYPOGRAPHY

$font_path: '/sites/all/themes/calinerra/fonts/';

$icon-font:           'icons';
$default-font:        'Verdana', 'Arial', 'Helvetica', 'sans-serif';
$heading-font:        'Tstar Headline', 'Arial', 'Helvetica', 'sans-serif';
$bold-font:           'Tstar Bold', 'Arial', 'Helvetica', 'sans-serif';
$default-font-size:   14;

// TRANSITION
// More examples at http://easings.net/

$easeIn:                    cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeOut:                   cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOut:                 cubic-bezier(0.645, 0.045, 0.355, 1);

$default-transition-time:   0.25s;
$slow-transition-time:      0.25s;
$ultra-slow-transition-time: 0.25s;

$default-transition:        $easeInOut $default-transition-time;
$slow-transition:           $easeInOut $slow-transition-time;
$ultra-slow-transition:     $easeInOut $ultra-slow-transition-time;

$transform-left-right:      translate3d(-50%, 0, 0);
$transform-right-left:      translate3d(50%, 0, 0);
$transform-reset:           translate3d(0, 0, 0);
$scale3d:                   scale3d(1, 1, 1);
$scale-width:               scale(1, 0);
$scale-height:              scale(0, 1);


// DEVICE SPECIFIC

$pixel-ratio:         "-webkit-min-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 1.5";

// LAYOUT

$default-border:         solid 1px $color-border;
$default-border-radius:  0.25rem;
$default-box-shadow:     2px 2px 4px $color-shadow;
$default-text-shadow:    2px 2px 4px $color-shadow;
$shadow-inset-big:       inset 0 0 32px 0 $color-black;

// FORMS

$form-field-border-radius:  0.125rem;
$form-field-padding:        0.5rem;
$form-field-border:         solid 1px $color-border;
$form-field-bgcolor:        $color-grey;

// BUTTONS

$default-btn-type:    medium;
$default-btn-color:   primary;

$btn-colors: (
        primary:    $color-primary,
        secondary:  $color-secondary,
        dark:       $color-dark,
);
